import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import GameModel from '../models/GameModel';
import gameConfig from '../configs/gameConfig';
import ServerManager from '../api/ServerManager';

export default class RequestRollingState extends BaseState {
  constructor(stateData) {
    super(stateData);

    this.timeout = null;
    GameModel.startRequestTime = 0;
  }
  async start() {
    super.start();
    GameModel.startRequestTime = Date.now();
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    if (!EntryPoint.GameModel.isFreeSpinsMode) {
      OPWrapperService.freeBetsController.decreaseFreeBets();
      OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.bet;
    }
    await ServerManager.onStartSpin();
    const delay = gameConfig.MINIMAL_SPIN_TIME - (Date.now() - GameModel.startRequestTime);
    this.timeout = setTimeout(this.complete.bind(this), delay);
  }

  onEnd() {
    super.onEnd();
    clearTimeout(this.timeout);
    EntryPoint.GameSettings.quickStop = false;
  }
}

import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import ServerManager from '../api/ServerManager';

export default class BombFeatureState extends BaseState {
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_BOMB_ACTION_END, this.complete, this);
  }

  async start() {
    super.start();
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    await ServerManager.useBomb();
    this.useBomb();
  }

  useBomb() {
    GlobalDispatcher.dispatch(eEventTypes.EET_USE_BOMB);
  }
}

import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eEventTypes from '../enums/eEventTypes';

export default class IdleState extends BaseState {

  constructor(stateData) {
    super(stateData);

    this.intervalDelay = 4000;
    this.interval = null;
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('autoPlaySettings:activeChanged', this.onAutoPlayChanged, this);
    GlobalDispatcher.add('autoPlaySettings:pausedChanged', this.onAutoPlayChanged, this);
  }

  onAutoPlayChanged(event) {
    if (this.active && EntryPoint.AutoPlaySettings.active && !EntryPoint.AutoPlaySettings.paused) {
      this.stop();
    }
  }

  start() {
    super.start();
    window.OPWrapperService.freeBetsController.updateTotalWin();
    if (!EntryPoint.GameModel.isFreeSpinsMode) {
      window.OPWrapperService.freeBetsController.show();
      window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    }
    if (
      window.OPWrapperService.freeBetsController.isFirstFreeBet
      || window.OPWrapperService.freeBetsController.isLastFreeBet
      || window.OPWrapperService.realityCheck.blockedGame
    ) EntryPoint.AutoPlaySettings.active = false;
    EntryPoint.GameModel.respinReelIndex = null;

    if (
      EntryPoint.AutoPlaySettings.active
      && EntryPoint.GameModel.bet > EntryPoint.GameModel.balance
      && !EntryPoint.GameModel.isSpinWithoutBalanceCheck
    ) {
      EntryPoint.AutoPlaySettings.active = false;
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    } else if ((EntryPoint.AutoPlaySettings.active && !EntryPoint.AutoPlaySettings.paused) || EntryPoint.GameModel.isFreeSpinsMode) {
      return this.stop();
    }
    this.interval = setInterval(() => GlobalDispatcher.dispatch(eEventTypes.EET_FIELD_IDLE), this.intervalDelay);
  }

  stop() {
    clearInterval(this.interval);
    super.stop()
  }
}
